<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <div class="flex a-center"
           v-show="selectArrSize===0">
        <div class="topbar-btn m-r-25"
             @click.stop="onClickCreateGoods">
          <Icon custom="i-icon icon-add"
                size="32"
                class="m-r-8"></Icon>
          新增商品
        </div>
        <div class="topbar-btn m-r-25"
             @click.stop="onClickImport">
          <Icon custom="i-icon icon-import"
                size="32"
                class="m-r-8"></Icon>
          批量导入
        </div>
        <Select v-model="type"
                placeholder="农药类别"
                label-in-value
                @on-change="onChangeType"
                style="width:120px;margin-right:24px">
          <Option v-for="item in typeArr"
                  :value="item.id"
                  :label="item.name"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="dosage"
                placeholder="剂型"
                label-in-value
                @on-change="onChangeDosage"
                style="width:120px;margin-right:24px">
          <Option v-for="item in dosageArr"
                  :value="item.id"
                  :label="item.name"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <Input v-model="searchValue"
               placeholder="商品名称"
               search
               @on-search="onSearch"
               style="width:280px" />
      </div>
      <div class="flex a-center"
           v-show="selectArrSize>0">
        <div class="topbar-btn"
             @click.stop="batchDeleteGoods">
          <Icon custom="i-icon icon-delete"
                size="32"
                class="m-r-8"></Icon>
          删除
        </div>
      </div>
    </div>
    <div class="list">
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="columns"
               :data="data"
               @on-select="onSelect"
               @on-select-cancel="onSelectCancel"
               @on-select-all="onSelectAll"
               @on-select-all-cancel="onSelectAll">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button @click.stop="onClickOpenYards(row)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">开码</Button>
            <Button @click.stop="onClickEditGoods(row,1)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">查看</Button>
            <Button @click.stop="onClickEditGoods(row,2)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">编辑</Button>
            <Button @click.stop="onClickDeleteGoods(row)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">删除</Button>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>
    <!-- 批量导入抽屉 -->
    <Drawer :mask-closable="false"
            :closable="false"
            :mask="false"
            :transfer="false"
            inner
            scrollables
            :width="drawerWidth"
            v-model="drawerVisible">
      <div class="main"
           style="background-color:#fff"
           v-show="importStatus===1">
        <div class="topbar">
          <div class="flex a-center">
            <div class="topbar-btn m-r-40"
                 @click.stop="drawerVisible=false">
              <Icon custom="i-icon icon-back"
                    size="32"
                    class="m-r-8"></Icon>
              返回商品列表
            </div>
          </div>
        </div>
        <div class="importView">
          <img src="../../../assets/common/img_logo.png">
          <h2>农药登记证信息库</h2>
          <Input v-model="c_searchValue"
                 placeholder="请搜索持证企业"
                 size="large"
                 search
                 enter-button="搜索"
                 @on-search="onClickImportSearch"
                 class="m-t-30"
                 style="width:656px" />
        </div>
      </div>
      <div class="main"
           v-show="importStatus===2">
        <div class="topbar">
          <div class="flex a-center">
            <div class="topbar-btn m-r-40"
                 @click.stop="drawerVisible=false">
              <Icon custom="i-icon icon-back"
                    size="32"
                    class="m-r-8"></Icon>
              返回
            </div>
            <Select v-model="c_type"
                    placeholder="农药类别"
                    style="width:120px;margin-right:24px">
              <Option v-for="item in typeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <Input v-model="c_searchValue"
                   placeholder="持证企业"
                   clearable
                   style="width:220px;margin-right:24px" />
            <Input v-model="c_searchNum"
                   placeholder="登记证号"
                   clearable
                   style="width:160px;margin-right:24px" />
            <Input v-model="c_searchName"
                   placeholder="农药名称"
                   clearable
                   style="width:160px;margin-right:24px" />
            <Button type="primary"
                    class="m-r-20"
                    style="border-radius: 18px;"
                    @click.stop="onSearchChild">搜索</Button>
            <Button type="primary"
                    :disabled="c_selectArrSize===0||disabled"
                    @click="disabled?'':importGoods()">批量添加</Button>
          </div>
        </div>
        <div class="list">
          <div class="table">
            <Table ref="c_table"
                   :height="tableHeight"
                   :columns="c_columns"
                   :data="c_data"
                   @on-select="onSelectChild"
                   @on-select-cancel="onSelectCancelChild"
                   @on-select-all="onSelectAllChild"
                   @on-select-all-cancel="onSelectAllChild">
            </Table>
            <Page class="m-t-10 m-l-10"
                  :total="c_totalPage"
                  :current="c_pageNum"
                  :page-size="c_pageSize"
                  show-total
                  show-sizer
                  show-elevator
                  @on-change="onPageChangeChild"
                  @on-page-size-change="onPageSizeChangeChild" />
          </div>
        </div>
      </div>
    </Drawer>
    <GoodsDrawer :width="drawerWidth"
                 v-model="goodsVisible"
                 :goodsId="goodsId"
                 :type="operType"
                 @on-click-confirm="onClickConfirm"
                 @on-click-delete="onClickDetailDelete"></GoodsDrawer>
  </div>
</template>

<script>
import { getGoodsList, getLicenseList, importGoods, deleteGoods, getLicenseTypeList, getLicenseDosageList } from '../../../services/goodsapi';
import GoodsDrawer from '../../../components/product/GoodsDrawer';
export default {
  components: { GoodsDrawer },
  data () {
    return {
      type: 0,
      typeName: '',
      typeArr: [
        {
          id: 0,
          name: '全部类别'
        }
      ],
      dosage: 0,
      dosageName: '',
      dosageArr: [
        {
          id: 0,
          name: '全部剂型'
        }
      ],
      searchValue: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      columns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 80,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '农药类别',
          key: 'pestType',
          tooltip: true,
          width: 160
        },
        {
          title: '登记证号',
          key: 'regSerial',
          tooltip: true,
          width: 160
        },
        {
          title: '商品名称',
          key: 'goodsName',
          tooltip: true,
          width: 160
        },
        {
          title: '有效成分含量',
          key: 'mainIngredient',
          tooltip: true,
          minWidth: 120
        },
        {
          title: '剂型',
          key: 'dosageForm',
          tooltip: true,
          width: 140
        },
        {
          title: '规格',
          key: 'spec',
          tooltip: true,
          minWidth: 120,
        },
        {
          title: '持证企业',
          key: 'entName',
          tooltip: true,
          minWidth: 240
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'right'
        }
      ],
      data: [],
      selectArr: new Set(),
      selectArrSize: 0,
      /* 批量导入 */
      drawerWidth: 0,
      drawerVisible: false,
      importStatus: 1,
      c_type: 0,
      c_searchValue: '',
      c_searchNum: '',
      c_searchName: '',
      c_totalPage: 0,
      c_pageNum: 1,
      c_pageSize: 20,
      c_columns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 80,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '农药类别',
          key: 'className',
          tooltip: true,
          width: 140
        },
        {
          title: '登记证号',
          key: 'registrationNo',
          tooltip: true,
          width: 160
        },
        {
          title: '农药名称',
          key: 'registrationName',
          tooltip: true,
          width: 160
        },
        {
          title: '有效成分含量',
          key: 'totalContent',
          tooltip: true,
          width: 180
        },
        {
          title: '剂型',
          key: 'dosage',
          tooltip: true,
          width: 140
        },
        {
          title: '有效期至',
          key: 'validEndDate',
          tooltip: true,
          align: 'center',
          width: 140
        },
        {
          title: '持证企业',
          key: 'enterpriseName',
          tooltip: true,
          minWidth: 160
        }
      ],
      c_data: [],
      c_selectArr: new Set(),
      c_selectArrSize: 0,
      disabled: false,
      goodsId: 0,
      goodsVisible: false,
      operType: 0
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 260
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 260
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      this.getGoodsList()
      this.getLicenseTypeList()
      this.getLicenseDosageList()
    },
    onSelectAll (selection) {
      if (selection.length === 0) {
        let data = this.$refs.table.data
        data.forEach((item) => {
          if (this.selectArr.has(item.goodsId)) {
            this.selectArr.delete(item.goodsId)
          }
        })
      } else {
        selection.forEach(item => {
          this.selectArr.add(item.goodsId)
        })
      }
      this.selectArrSize = this.selectArr.size
    },
    //  选中某一行
    onSelect (selection, row) {
      this.selectArr.add(row.goodsId)
      this.selectArrSize = this.selectArr.size
    },
    //  取消某一行
    onSelectCancel (selection, row) {
      this.selectArr.delete(row.goodsId)
      this.selectArrSize = this.selectArr.size
    },
    setChecked () {
      var objData = this.$refs.table.$refs.tbody.objData;
      for (let index in objData) {
        if (this.selectArr.has(objData[index].goodsId)) {
          objData[index]._isChecked = true
        }
      }
    },
    onSelectAllChild (selection) {
      if (selection.length === 0) {
        let data = this.$refs.c_table.data
        data.forEach((item) => {
          if (this.c_selectArr.has(item.registrationNo)) {
            this.c_selectArr.delete(item.registrationNo)
          }
        })
      } else {
        selection.forEach(item => {
          this.c_selectArr.add(item.registrationNo)
        })
      }
      this.c_selectArrSize = this.c_selectArr.size
    },
    //  选中某一行
    onSelectChild (selection, row) {
      this.c_selectArr.add(row.registrationNo)
      this.c_selectArrSize = this.c_selectArr.size
    },
    //  取消某一行
    onSelectCancelChild (selection, row) {
      this.c_selectArr.delete(row.registrationNo)
      this.c_selectArrSize = this.c_selectArr.size
    },
    setCheckedChild () {
      var objData = this.$refs.c_table.$refs.tbody.objData;
      for (let index in objData) {
        if (this.c_selectArr.has(objData[index].registrationNo)) {
          objData[index]._isChecked = true
        }
      }
    },
    // 获取农药类别
    getLicenseTypeList () {
      getLicenseTypeList({ type: 1 }).then(res => {
        this.typeArr = this.typeArr.concat(res.data)
      })
    },
    // 获取剂型
    getLicenseDosageList () {
      getLicenseDosageList({ type: 1 }).then(res => {
        this.dosageArr = this.dosageArr.concat(res.data)
      })
    },
    onChangeType (obj) {
      this.typeName = obj.label
      this.onSearch()
    },
    onChangeDosage (obj) {
      this.dosageName = obj.label
      this.onSearch()
    },
    onSearch () {
      this.selectArr = new Set()
      this.selectArrSize = 0
      this.pageNum = 1
      this.getGoodsList()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getGoodsList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getGoodsList()
    },
    // 获取商品列表
    getGoodsList () {
      let params = {
        goodsType: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (this.type) params.childTypeName = this.typeName
      if (this.dosage) params.dosageForm = this.dosageName
      if (this.searchValue) params.goodsName = this.searchValue
      getGoodsList(params).then(res => {
        this.totalPage = res.data.total
        this.data = res.data.list.map(item => {
          item.pestType = item.extra.pestType
          item.regSerial = item.extra.regSerial
          item.mainIngredient = item.extra.mainIngredient
          item.dosageForm = item.extra.dosageForm
          item.entName = item.holderList.length > 0 ? item.holderList[0].entName : ''
          return item
        })
        this.$nextTick(() => {
          this.setChecked();
        })
      })
    },
    onSearchChild () {
      this.c_selectArr = new Set()
      this.c_selectArrSize = 0
      this.c_pageNum = 1
      this.getLicenseList()
    },
    // 页数改变
    onPageChangeChild (event) {
      this.c_pageNum = event
      this.getLicenseList()
    },
    // 每页条数改变
    onPageSizeChangeChild (event) {
      this.c_pageSize = event
      this.getLicenseList()
    },
    // 点击批量导入
    onClickImport () {
      this.c_type = 0
      this.c_searchValue = ''
      this.c_pageNum = 1
      this.c_selectArr = new Set()
      this.c_selectArrSize = 0
      this.importStatus = 1
      this.drawerVisible = true
      this.getLicenseList()
    },
    // 点击批量导入搜索
    onClickImportSearch () {
      this.importStatus = 2
      this.onSearchChild()
    },
    // 获取证件列表
    getLicenseList () {
      let params = {
        type: 1,
        pageNum: this.c_pageNum,
        pageSize: this.c_pageSize
      }
      if (this.c_type) params.classId = this.c_type
      if (this.c_searchValue) params.entName = this.c_searchValue
      if (this.c_searchNum) params.registrationNo = this.c_searchNum
      if (this.c_searchName) params.name = this.c_searchName
      getLicenseList(params).then(res => {
        this.c_totalPage = res.data.total
        this.c_data = res.data.list
        this.$nextTick(() => {
          this.setCheckedChild();
        })
      })
    },
    // 批量导入商品
    importGoods () {
      this.disabled = true
      let params = {
        type: 1,
        regSerialList: [...this.c_selectArr]
      }
      importGoods(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('导入成功')
          this.drawerVisible = false
          this.disabled = false
          this.getGoodsList()
        } else {
          this.disabled = false
          if (res.msg) this.$Message.info(res.msg)
        }
      }).catch(err => {
        this.disabled = false
      })
    },
    // 点击删除商品
    onClickDeleteGoods (row) {
      this.onDeleteGoods([row.goodsId])
    },
    // 批量删除商品
    batchDeleteGoods () {
      this.onDeleteGoods([...this.selectArr])
    },
    // 删除商品
    onDeleteGoods (list) {
      this.$Modal.confirm({
        title: '删除商品',
        content: '<p>是否删除商品？</p>',
        onOk: () => {
          let params = {
            goodsType: 1,
            idList: list.join()
          }
          deleteGoods(params).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功')
              this.selectArr = new Set()
              this.selectArrSize = 0
              this.getGoodsList()
              this.goodsVisible = false
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 点击新建商品
    onClickCreateGoods () {
      this.goodsId = 0
      this.operType = 1
      this.goodsVisible = true
    },
    // 点击编辑商品
    onClickEditGoods (row, type) {
      this.goodsId = row.goodsId
      this.operType = type === 1 ? 3 : 2
      this.goodsVisible = true
    },
    // 点击保存
    onClickConfirm () {
      this.getGoodsList()
    },
    // 点击详情内的删除
    onClickDetailDelete () {
      this.selectArr = new Set()
      this.selectArrSize = 0
      this.getGoodsList()
    },
    // 点击开码
    onClickOpenYards (row) {
      this.$router.push({
        name: 'openyards',
        params: {
          id: row.goodsId
        }
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.main {
  height: 100%;
  background-color: #f9f9ff;
  .title {
    font-size: 22px;
    line-height: 66px;
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #d3d1e7;
    position: relative;
    .crumbs {
      line-height: 14px;
      position: absolute;
      left: 23px;
      bottom: 16px;
      span {
        font-size: 14px;
        line-height: 1;
        color: #999;
      }
    }
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    .topbar-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #666;
      &:hover {
        color: #0652dd;
      }
    }
    ::v-deep {
      .ivu-select-selection,
      .ivu-input {
        background-color: transparent;
        border-color: #9c9ccd;
        border-radius: 18px;
      }
      .ivu-select-selection span {
        padding-left: 12px;
      }
      .ivu-input {
        padding: 4px 12px;
      }
    }
  }
  .list {
    padding: 20px;
    .table {
      padding: 20px;
      background-color: #fff;
    }
    ::v-deep {
      .ivu-table-cell {
        padding: 0 8px;
      }
    }
  }
  ::v-deep .ivu-drawer {
    top: 64px;
    .ivu-drawer-body {
      padding: 0;
    }
  }
  .importView {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 92px;
      height: 98px;
    }
    h2 {
      font-size: 42px;
      line-height: 59px;
      font-weight: 600;
      color: #3d3e58;
      margin-top: 16px;
    }
  }
}
::v-deep .ivu-select-dropdown-list {
  max-height: 300px;
}
</style>
